<template>
    <div class="page">

        <div class="menu_content" v-if="isDataLoaded">
            <div class="con_left" :style="{ 'height': `${windowHeight}px` }">
                <div v-for="(item, index) in categories" :key="index" :class="{ act: item.id == searchData.categoryId }"
                    @click="onCategory(item.id)">{{ item.name }}</div>
            </div>

            <div class="con_right" :style="{ 'height': `${windowHeight}px` }">
                <div class="item-box" v-if="!isEmpty(list)">
                    <div class="list-box">
                        <van-list
                            v-model="loading"
                            :finished="finished"
                            finished-text="没有更多了"
                            @load="onLoad"
                        >
                            <div class="list" data-flex="cross:center" v-for="(item, i) in list" :key="i" @click="toDetail(item.id)">
                                <img class="icon" data-flex-box="0" :src="item.mainImage | qiniuResource"
                                    mode="aspectFill" />
                                <div data-flex="dir:top main:justify">
                                    <div class="ec2 f-fs14 f-fwb">{{item.title}}</div>
                                    <div class="f-fs14 f-fwb">
                                        <span class="f-fs12">￥</span>
                                        <span class="f-fs16">{{item.price}}</span>
                                    </div>
                                    <!-- <div class="f-fs14 f-fc10">
                                        <span class="f-fs12">￥</span>
                                        <span class="f-fs12 f-tlt">{{item.costprice}}</span>
                                    </div> -->
                                </div>
                            </div>
                        </van-list>
                    </div>
                </div>
                
                <van-empty v-else description="暂无相关商品" />
            </div>
        </div>

        <TabsBox ref="tabsBox" :active="1" />
    </div>
</template>

<script>
import mix1 from '@/mixins/page'
import minList from '@/mixins/list'
import { uiShowLoading, uiHideLoading, uiAlert, uiConfirm } from '@/utils/ui'
import { delayAsync } from '@/utils/common'
import { Button, NumberKeyboard, Field, Empty, List } from 'vant'
import MgPopup from '@/components/common/mg-popup'
import TabsBox from '@/components/common/tabs'

export default {
    mixins: [mix1, minList],
    components: {
        MgPopup, TabsBox, Button, NumberKeyboard,
        [Field.name]: Field,
        [Button.name]: Button,
        [Empty.name]: Empty,
        [List.name]: List,
        
    },
    data() {
        return {
            isDataLoaded: false,
            categories: [],
            searchData: {
                categoryId : -255
            },
        }
    },
    methods: {
        onCategory(id) {
            this.searchData.categoryId = id
            this.getList(true)
        },
		toDetail(id) {
            this.$router.push('/goods/detail?id=' + id)
		},
        onLoad() {
            // 下拉
            // 加载状态开始
            this.loading = true;
            this.pagination.page += 1
            
            this.getList()
        },
        async categoriesList() {
            const promise = this.apiGet('categories.list')

            uiShowLoading()
            await delayAsync(500)

            let apiResult = null

            try {
                apiResult = await promise
            } catch (ex) {
                apiResult = null
            }

            uiHideLoading()

            if (this.hasException(apiResult)) {
                return
            }

            this.categories = [...[{ id: -255, name: '全部商品' }], ...apiResult.list]
            
        },
        async getList(type) {
            if(type){
                this.loading = true;
                this.finished = false;
                this.pagination.page = 1
                this.list = []
            }
            uiShowLoading()
            await delayAsync(500)

            const promise = this.apiGet('goods.list', {
                ...this.searchData,
                ...this.pagination
            })

            let apiResult = null

            try {
                apiResult = await promise
            } catch (ex) {
                apiResult = null
            }

            uiHideLoading()

            if (this.hasException(apiResult)) {
                return
            }
            this.isDataLoaded = true
            
            // 加载状态结束
            this.loading = false;

            this.list = [...this.list, ...apiResult.list]

            // 数据全部加载完成
            if (this.list.length >= apiResult.pagination.recordTotal) {
                this.finished = true;
            }
        },
    },
    mounted() {

    },
    created() {
        this.categoriesList()
        this.getList(true)
    }
}
</script>

<style lang="less" scoped>
.page {

    .menu_content {
        width: 100%;
        display: flex;
        align-items: flex-start;

        .con_left {
            box-sizing: border-box;
            width: 168px;
            overflow-x: hidden;
            overflow-y: scroll;
            background-color: #ffffff;
            flex-shrink: 0;
            border-radius: 0 15px 15px 0;
            padding-bottom: 100px;
            box-sizing: border-box;

            >div {
                width: 168px;
                height: 118px;
                color: #333;
                font-size: 14px;
                text-align: center;
                line-height: 118px;
            }

            .act {
                width: 168px;
                // color: #E61B1B;
                font-size: 14px;
                font-weight: bold;
                font-weight: bold;
                text-align: center;
                position: relative;
                background-color: rgb(248, 248, 248);
            }

            .act:after {
                width: 6px;
                height: 44px;
                background-color: #E61B1B;
                border-radius: 0px 6px 6px 0px;
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto 0;
            }
        }

        .con_right {
            box-sizing: border-box;
            flex-grow: 1;
            flex-shrink: 1;
            padding: 20px 25px;
            box-sizing: border-box;
            overflow: auto;
            padding-bottom: 100px;
            box-sizing: border-box;

            .scroll-div {}
        }

        .no_list {
            text-align: center;
            color: #999;
            font-size: 14px;
            padding-top: 500px;
        }

        .item-box {
            box-sizing: border-box;

            .category-name {
                padding: 20px 0;
                color: #333;
                font-weight: bold;
                font-size: 14px;
                margin-top: 20px;
            }

            .list-box {
                .list {
                    background: #fff;
                    padding: 20px;
                    box-sizing: border-box;
                    border-radius: 15px;
                    margin-bottom: 20px;

                    >img {
                        width: 160px;
                        height: 160px;
                        border-radius: 10px;
                        margin-right: 20px;
                    }

                    >div {
                        height: 150px;
                        width: 100%;
                        .add {
                            width: 48px;
                            height: 48px;
                        }
                    }
                }
            }
        }
    }
}
</style>